import { templateSettings, template } from "lodash";
///font variable structure:///
// font size will start with -> themeFontSize
// font family will start with -> themeFontFamily

///colors (background, border, text color) variable structure:///
// text color will start with -> themeColorText
// background color will start with -> themeColorBg
// border color will start with -> themeColorBorder

///shadow variable structure:///
// shadow will start with -> themeBoxShadow

const defaultTheme = {
  themeFontFamilyBbase: '"Poppins", sans-serif, Arial',
  themeFontSizeBase: '14px',
  themeImgBgBase: "url(/static/media/bg.edd28cee.png)",
  themeColorBgBase: "#070A13",
  themeImgBgAccountContainer: `null`,
  themeColorBgHomeBalanceTabContainer: "rgba(27,40,75,0.5)",
  themeColorBgHomeBalanceTabActiveContainer: "rgb(27,40,75)",
  themeColorBgHomeBalanceInputContainer: "#05102F",
  themeColorBgPrimary: "#1B284B",
  themeColorTextBase: "#fff",
  themeColorBgHomeVipContainer: "linear-gradient(to bottom, #41A3EF, #E2F6FA)",
  themeBoxShadowHomeBalanceHeader: "inset 0 7px 10px -7px #1DA1F2",
  themeBoxShadowHomeBalanceItem: "inset 0 -7px 10px -7px #1DA1F2",
  themeBoxShadowHomeBcItem: "inset 0 2px 4px 0 rgba(29, 161, 242,0.8)",
  themeColorTextHomeBcItem: "#29D5D9",
  themeColorBgPromoVipContainer:
    "linear-gradient(180deg, rgba(17, 4, 42, 0) 0%, #11042A 58.33%, #11042A 100%)",
  themeColorHomeGameLink: "#131c34",
  themeColorHomeGameActiveLink: "#131C34",
  themeColorIconHomeBalance: "#29d5d9",
  themeColorBgModal: '#10182d',
  themeColorTextPrimary: '#29d5d9',
  themeColorBgAllBoxWithIcon: '#1b284b',
  themeColorBgSearch: '#131c34',
  themeColorTextGray2: '#94a3b8',
  themeColorBgCustomFormInput: '#0d1426',
  themeColorBgMytabsActive: '#2c4077',
  themeColorIconDisabled: '#94a3b8',
  themeColorTextGray: '#64748b',
  themeColorBgDropDown: '#1B284B',
  themeColorBgTableHeader: '#10182d',
  themeColorBgDailyCheckinIconContainer: '#1F2E56',
  themeColorBgDailyCheckinDayContainer: '#FFFFFF',
  themeColorBgBottomMenu: 'rgb(15, 23, 42)',
  themeColorBgFooter: '#0f172a',
  themeColorTextBottomMenu: 'rgb(71, 85, 105)',
  themeColorTextBottomMenuActive: '#29D5D9',
  themeColorTextGray4: '#cbd5e1',
  themeColorBgCardHeader: '#002e6c',
  themeColorBorderWalletLine: '#343283',
  themeColorTextHomeCategory:' #94a3b8',
  themeBorderHomeBcItemWrapper: 'unset',
  themeColorTextGray5: '#cbd5e1',
  themeColorBgVipBottom: "linear-gradient(to right, rgba(86, 0, 1, 0.1), rgb(86, 0, 1) 10%, rgb(86, 0, 1) 90%, rgba(86, 0, 1, 0.1))",
  themeColorBgKeyboard: '#0b101e',
  themeColorBgKeyboardPad: '#131c34',
  themeColorBgShopItem: 'linear-gradient( 180deg, rgba(51, 105, 255, 0.24) -6.16%, rgba(27, 42, 84, 0.24) 110.96%)',
  themeColorBgPopUp: 'rgb(5, 16, 47)',
  themeColorBgReminderYellow:  'rgba(111, 203, 32, 0.12)',
  themeColorBgBody: '#070a13',
  themeMinWidthHtml: 'auto',
  themeColorTextTableThHeader: '#29d5d9',
  themeColorTextStyledSelect: '#29D5D9',
};

const darkTheme = {
  themeFontFamilyBbase: '"Poppins", sans-serif, Arial',
  themeFontSizeBase: '14px',
  themeImgBgBase: "unset",
  themeColorBgBase: "#0d101a",
  themeImgBgAccountContainer: "unset",
  themeColorBgAccountContainer: "#111526",
  themeColorBgHomeBalanceTabContainer: "rgba(27,40,75,0.5)",
  themeColorBgHomeBalanceTabActiveContainer: "#111526",
  themeColorBgHomeBalanceInputContainer: "#171c31",
  themeColorBgPrimary: "#171c31",
  themeColorTextBase: "#fff",
  themeColorBgHomeVipContainer: "linear-gradient(to bottom, #41A3EF, #E2F6FA)",
  themeBoxShadowHomeBalanceHeader: "inset 0 7px 10px -7px #da11cb",
  themeBoxShadowHomeBalanceItem: "inset 0 -7px 10px -7px #da11cb",
  themeBoxShadowHomeBcItem: "inset 0 2px 4px 0 rgba(218,17,203,0.8)",
  themeColorTextHomeBcItem: "#da11cb",
  themeColorBgPromoVipContainer:
    "linear-gradient(180deg, rgba(17, 4, 42, 0) 0%, #11042A 58.33%, #11042A 100%)",
  themeColorHomeGameLink: "#111526",
  themeColorHomeGameActiveLink: "#131C34",
  themeColorIconHomeBalance: "#da11cb",
  themeColorBgModal: '#0d101a',
  themeColorTextPrimary: '#da11cb',
  themeColorBgAllBoxWithIcon: '#171c31',
  themeColorBgSearch: '#171c31',
  themeColorTextGray2: '#94a3b8',
  themeColorBgCustomFormInput: '#171c31',
  themeColorBgMytabsActive: '#293355',
  themeColorIconDisabled: '#94a3b8',
  themeColorTextGray: '#64748b',
  themeColorBgDropDown: '#293355',
  themeColorBgTableHeader: '#293355',
  themeColorBgDailyCheckinIconContainer: '#1F2E56',
  themeColorBgDailyCheckinDayContainer: '#FFFFFF',
  themeColorBgBottomMenu: '#171c31',
  themeColorBgFooter: '#0f172a',
  themeColorTextBottomMenu: 'rgb(71, 85, 105)',
  themeColorTextBottomMenuActive: '#da11cb',
  themeColorTextGray4: '#cbd5e1',
  themeColorBgCardHeader: '#002e6c',
  themeColorBorderWalletLine: '#343283',
  themeColorTextHomeCategory:' #94a3b8',
  themeBorderHomeBcItemWrapper: 'unset',
  themeColorTextGray5: '#cbd5e1',
  themeColorBgVipBottom: "linear-gradient(to right, rgba(86, 0, 1, 0.1), rgb(86, 0, 1) 10%, rgb(86, 0, 1) 90%, rgba(86, 0, 1, 0.1))",
  themeColorBgKeyboard: '#0d101a',
  themeColorBgKeyboardPad: '#171c31',
  themeColorBgShopItem: '#171c31',
  themeColorBgPopUp: '#171c31',
  themeColorBgReminderYellow:  'rgba(111, 203, 32, 0.12)',
  themeColorBgBody: '#000',
  themeMinWidthHtml: 'auto',
  themeColorTextTableThHeader: '#FFFFFF',
  themeColorTextStyledSelect: '#FFFFFF',
};

const lightTheme = {
  themeFontFamilyBbase: '"Poppins", sans-serif, Arial',
  themeFontSizeBase: '14px',
  themeImgBgBase: "unset",
  themeColorBgBase: "#e7e6e6",
  themeImgBgAccountContainer: "unset",
  themeColorBgAccountContainer: "rgb(244, 245, 246)",
  themeColorBgHomeBalanceTabContainer: "#f4f5f6",
  themeColorBgHomeBalanceTabActiveContainer: "#fff",
  themeColorBgHomeBalanceInputContainer: "#f4f5f6",
  themeColorBgPrimary: "#fff",
  themeColorTextBase: "#000",
  themeColorBgHomeVipContainer: "#29d5d9",
  themeBoxShadowHomeBalanceHeader: "inset 0 7px 10px -7px #5ae4e7",
  themeBoxShadowHomeBalanceItem: "unset",
  themeBoxShadowHomeBcItem: "unset",
  themeColorTextHomeBcItem: "#000",
  themeColorBgPromoVipContainer:
    "linear-gradient(180deg, rgba(17, 4, 42, 0) 0%, #8d849d 58.33%, #11042A 100%)",
  themeColorHomeGameLink: "rgb(244, 245, 246)",
  themeColorHomeGameActiveLink: "rgb(244, 245, 246)",
  themeColorIconHomeBalance: "#4b4a4a",
  themeColorBgModal: '#fff',
  themeColorTextPrimary: '#000',
  themeColorBgAllBoxWithIcon: 'rgb(244, 245, 246)',
  themeColorBgSearch: 'rgb(244, 245, 246)',
  themeColorTextGray2: '#222427',
  themeColorBgCustomFormInput: 'rgb(244, 245, 246)',
  themeColorBgMytabsActive: '#fff',
  themeColorIconDisabled: '#353333',
  themeColorTextGray: '#373c45',
  themeColorBgDropDown: '#fff',
  themeColorBgTableHeader: 'rgb(255 255 255)',
  themeColorBgDailyCheckinIconContainer: '#fff',
  themeColorBgDailyCheckinDayContainer: 'rgb(244, 245, 246)',
  themeColorBgBottomMenu: 'rgb(244, 245, 246)',
  themeColorBgFooter: '#656363',
  themeColorTextBottomMenu: '#63717b',
  themeColorTextBottomMenuActive: '#29D5D9',
  themeColorTextGray4: '#444649',
  themeColorBgCardHeader: 'rgb(244, 245, 246)',
  themeColorBorderWalletLine: '#b9b9bd',
  themeColorTextHomeCategory:' #000',
  themeBorderHomeBcItemWrapper: '1px solid #29d5d9',
  themeColorTextGray5: '#000',
  themeColorBgVipBottom: "linear-gradient(to right, rgba(86, 0, 1, 0.1), rgb(255, 255, 255) 10%, rgb(221, 221, 221) 90%, rgba(86, 0, 1, 0.1))",
  themeColorBgKeyboard: '#d1d1d1',
  themeColorBgKeyboardPad: '#a7a7a7',
  themeColorBgShopItem: 'rgb(244, 245, 246)',
  themeColorBgPopUp: 'rgb(63 63 63)',
  themeColorBgReminderYellow:  '#f4f5f6',
  themeColorBgBody: '#e7e6e6',
  themeMinWidthHtml: 'auto',
  themeColorTextTableThHeader: '#000',
  themeColorTextStyledSelect: '#000',
};

const forestTheme = {
  themeFontFamilyBbase: '"Poppins", sans-serif, Arial',
  themeImgBgBase: "unset",
  themeColorBgBase: "#194C38",
  themeColorTextBase: "#fff",
  themeMinWidthHtml: '500px',
}

const template1Theme = {
  themeFontFamilyBbase: '"Poppins", sans-serif, Arial',
  themeImgBgBase: "unset",
  themeColorBgBase: "#222222",
  themeColorBgPrimary: "#2D3035",
  themeColorBgSecondary: "#323232",
  themeColorBgSpecial: '#00000080',
  
  themeColorTextBase: "#fff",
  themeColorTextPrimary: '#000000',
  themeColorTextSecondary: '#45FF8B',
  themeColorTextPlaceholder: '#878787',
  themeColorTextSpecial:'#FCE977',
  themeColorTextTableHeader:'#00B052',


  themeColorBgSideMenuActive: '#003418',
  themeColorBgNav: '#0A0A0A',
  themeColorBgTableHeader: '#152F21',
  themeColorBgTableRow:'#004428',
  
  // themeColorBgBtnDefault: '#2E2F33',
  themeColorBgBtnPrimary: '#45FF8B',
  themeColorBgBtnSecondary: '#45FF8B',
  themeColorBgBtnSubmit: '#45FF8B',
  themeColorBgBtnSpecial: '#E6C252',
  themeColorBgBtnInfo: '#45FF8B',
  themeColorBgBtnLogin: 'linear-gradient(90deg, #FFD963 0%, #EBB614 100%)',
  // themeColorBgInputDefault: '#282828',

  themeColorBgInputPrimary: '#FFFFFF',
  themeColorBgInputSpecial: '#585858',

  themeMinWidthHtml: '500px',
}

const themeGenerator = (activeTheme, replaceValue = {}) => {
  let selectedThemeString = JSON.stringify(template1Theme);

  // if (activeTheme === "default") {
  //   selectedThemeString = JSON.stringify(defaultTheme);
  // } else if (activeTheme === "dark") {
  //   selectedThemeString = JSON.stringify(darkTheme);
  // } else if (activeTheme === "light") {
  //   selectedThemeString = JSON.stringify(lightTheme);
  // } else if (activeTheme === "forest") {
  //   selectedThemeString = JSON.stringify(forestTheme);
  // } else if (activeTheme === "template1") {
  //   selectedThemeString = JSON.stringify(template1Theme);
  // }

  templateSettings.interpolate = /{{([\s\S]+?)}}/g;
  const compiled = template(selectedThemeString);

  const result = compiled(replaceValue);

  return JSON.parse(result);
};

const themeIcons = {
  ///general
  menu: "menu",
  menu3: "menu3",
  angleRight: "chevron-right",
  angleLeft: "chevron-left",
  angleDown: "chevron-down",
  angleUp: "chevron-up",
  search: "search2",
  wallet: "okup_wallet",
  moreVertical: "more-vertical",
  home: "home",
  activity: "news-paper",
  account: "user-solid-circle",
  downlines: "icon-downline",
  referral: "icon-referral",
  sales: "chart1",
  home1: "home1",
  star: "star",
  close: "close",
  qrcode: "qrcode",
  user: "user",
  delete: "trash2",
  upload: "upload4",
  card: "credit-card",
  refresh: "spinner11",
  claim: "download5",
  okup_withdraw: "okup_withdraw",
  eye: "eye",
  eyeblocked: "eye-blocked",
  eyeBlocked: "eye-blocked",
  image: "image",
  bank: "bank",
  info: "info",
  cog: "cog",
  alertCircle: "alert-circle",
  edit: "edit-pencil",
  statistic: "stats-bars2",
  copy: "copy1",
  checkmark: "checkmark",
  huione: "huione",
  usdt: "usdt",
  bubbles: "bubbles",
  profile: "profile",
  usercheck: "user-check",
  giftpace: "giftpace",
  huione: "huione",
  usdt: "usdt",
  topkash: "topkash",
  sliderArrowRight: "slider-arrow-right",
  sliderArrowLeft: "slider-arrow-left",
  wingWeiluy:"wing-weiluy",
  facebook2:"facebook2",
  telegram:"telegram",
  whatsapp:"whatsapp",
  instagram:"instagram",
  bubbles:"bubbles",
  exclamation_solid:"exclamation-solid",
  menu1: "menu1",
  livechat_mobile:"livechat-mobile",
  envelope4:"envelope4",
  calender4:"calender4",
  deposit: "icon-deposit",
  withdraw: "icon-withdraw",
  refresh: "icon-refresh",
  checkmarkOutline: "checkmark-outline",
  gameCount: "icon-bullet",
  starEmpty: "star-empty",
  starFull: "star-full",
  fullscreen: "enlarge",
  exitFullScreen: "shrink",



  ///dashboard
  dashboardAnnouncement: "bullhorn",
  dashboardNotification: "bell",
  dashboardBalanceDeposit: "plus",
  dashboardBalanceWithdraw: "okup_withdraw",
  dashboardBalanceRestore: "undo",
  dashboardBalanceTransfer: "okup_transfer",
  dashboardBalanceWallet: "okup_wallet",
  dashboardCommission: "currency-dollar",
  dashboardCommissionClaim: "arrow-down3",
  dashboardCommissionDownlines: "share-2",
  dashboardCommissionReferral: "users2",
  dashboardCommissionSales: "chart1",
  dashboardPoints: "star-full",
  dashboardPointsCheckin: "calendar1",
  dashboardPointsShop: "shopping-bag",
  dashboardPointsHistory: "history",
  dashboardPointsRecord: "gift",
  lockClosed: "lock-closed",

  ///settings
  settingsLanguage: "conversation",
  settingsBank: "library",
  settingsPassword: "icon-password",
  settingsPin: "calculator1",
  settingsLivechat: "help-circle",
  settingsTerms: "file-text2",
  settingsLogout: "log-out",

  //profile
  profilePhone: 'phone',
  profileMail: 'mail1',
  
};

export { defaultTheme, darkTheme, lightTheme, forestTheme, template1Theme, themeGenerator, themeIcons };
